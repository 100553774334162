/*** Font Family ***/
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaBlack.eot');
	src: url('../fonts/SVN-ProximaNovaBlack.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaBlack.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaBlack.woff') format('woff'), url('../fonts/SVN-ProximaNovaBlack.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaBlack.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 900;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaBlackItalic.eot');
	src: url('../fonts/SVN-ProximaNovaBlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaBlackItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaBlackItalic.woff') format('woff'), url('../fonts/SVN-ProximaNovaBlackItalic.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaBlackItalic.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: italic;
	font-weight: 900;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaBold.eot');
	src: url('../fonts/SVN-ProximaNovaBold.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaBold.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaBold.woff') format('woff'), url('../fonts/SVN-ProximaNovaBold.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaBold.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaBoldItalic.eot');
	src: url('../fonts/SVN-ProximaNovaBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaBoldItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaBoldItalic.woff') format('woff'), url('../fonts/SVN-ProximaNovaBoldItalic.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaBoldItalic.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaExtraBold.eot');
	src: url('../fonts/SVN-ProximaNovaExtraBold.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaExtraBold.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaExtraBold.woff') format('woff'), url('../fonts/SVN-ProximaNovaExtraBold.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaExtraBold.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 800;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaExtraBoldItalic.eot');
	src: url('../fonts/SVN-ProximaNovaExtraBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaExtraBoldItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaExtraBoldItalic.woff') format('woff'), url('../fonts/SVN-ProximaNovaExtraBoldItalic.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaExtraBoldItalic.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: italic;
	font-weight: 800;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaItalic.eot');
	src: url('../fonts/SVN-ProximaNovaItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaItalic.woff') format('woff'), url('../fonts/SVN-ProximaNovaItalic.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaItalic.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaLight.eot');
	src: url('../fonts/SVN-ProximaNovaLight.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaLight.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaLight.woff') format('woff'), url('../fonts/SVN-ProximaNovaLight.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaLight.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaLightItalic.eot');
	src: url('../fonts/SVN-ProximaNovaLightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaLightItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaLightItalic.woff') format('woff'), url('../fonts/SVN-ProximaNovaLightItalic.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaLightItalic.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaRegular.eot');
	src: url('../fonts/SVN-ProximaNovaRegular.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaRegular.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaRegular.woff') format('woff'), url('../fonts/SVN-ProximaNovaRegular.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaRegular.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaSemiBold.eot');
	src: url('../fonts/SVN-ProximaNovaSemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaSemiBold.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaSemiBold.woff') format('woff'), url('../fonts/SVN-ProximaNovaSemiBold.ttf') format('truetype'), url('../fonts/SVN-ProximaNovaSemiBold.svg#svgFontName') format('svg');
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/SVN-ProximaNovaSemiBoldItalic.eot');
	src: url('../fonts/SVN-ProximaNovaSemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SVN-ProximaNovaSemiBoldItalic.woff2') format('woff2'), url('../fonts/SVN-ProximaNovaSemiBoldItalic.ttf') format('truetype');
	font-display: swap;
	font-style: italic;
	font-weight: 600;
}

/* vietnamese */
@font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsibZgmWBygaXxN.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsibJgmWBygaXxN.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsiYpgmWBygaQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczdb8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwdb8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXcSYJIheh6QaA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXYSYJIheh6QaA.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVNSTAyLFyeg_IDWvOJmVES_HwyNXgSYJIheh4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxdb8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2dbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2dbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2db8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3dbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3dbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3db8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80dbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80dbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80db8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1dbADdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1dbEDdzSqYUR0jg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1db8DdzSqYUQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG86Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG87Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HT4JG81Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ286Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ287Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HScJ281Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw4BXoYZ7AjSh8.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw5BXoYZ7AjSh8.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVPSTAyLFyeg_IDWvOJmVES_Hw3BXoYZ7Aj.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm86Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm87Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HTEJm81Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW86Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW87Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HToIW81Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG86Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG87Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSMIG81Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI286Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI287Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HSQI281Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im86Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im87Rb0JcBaoUUU.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Be Vietnam Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/bevietnampro/v10/QdVMSTAyLFyeg_IDWvOJmVES_HS0Im81Rb0JcBao.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

body {
    font-family:'Proxima Nova';
    color: #17181C;
    font-size: 14px;
}

button, input, optgroup, select, textarea,
h1, h2, h3, h4, h5, h6 {
	font-family:'Proxima Nova';
}

h1, h2, h3, h4, h5, h6 {
    color: #17181C;
}

:focus-visible {
    outline: none;
}

/*** Style Header ***/
.container {
    padding: 0 15px;
}

@media (min-width: 1300px){
    .container {
        max-width: 1300px;
    }
}

@media (max-width: 640px) {
    .container {
        padding: 0 12px;
    }

    .custom-slider .carousel-cell {
        padding: 6px!important;
    }
}

@media (max-width: 1280px) {
    button.btn-addtocart-product-item {
        max-width: unset!important;
        margin-top: 4px;
    }

    .product-options-and-addtocart {
        display: unset!important;
    }

    .product-item--quantity-group .input-group {
        margin-right: 0!important;
    }

    .product-item--quantity-group .input--quantity {
        max-width: unset!important;
    }

    button.btn.btn-success.btn-sm.rounded-pill.btn-addtocart-product-item.height-32px {
        width: 100%;
        margin-top: 36px;
    }
}

.header-bar-top .row {
    margin: 0;
}

.header-bar-top {
    background-color: #6AA543;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 0;
    min-height: 28px;
}

.col-header-bar-phone span {
    padding: 0 5px;
}

.col-header-bar-languages-login {
    min-width: 152px;
}

.col-header-bar-languages-login .languages {
    margin-right: 10px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.col-header-bar-languages-login .languages a {
    margin-right: 8px;
    display: inline-block;
}

.col-header-bar-languages-login ul.nav {
    align-items: center;
}

.col-header-bar-languages-login ul.nav li.user-signin a.dropdown-toggle {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none !important;
}

.header-top {
    padding: 13px 0px 0px 0px;
    background-color: #ffffff;
}

.col-header-seach {
    position: relative;
}

.col-header-seach .cate-search {
    padding: 8px 16px 8px 12px;
    border-radius: 8px 0px 0px 8px;
    font-size: 14px;
    color: #17181C;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 45px !important;
    background-color: rgba(213, 215, 221, 0.5);
}

.col-header-seach .cate-search:focus {
    box-shadow: none;
    color: #fff;
    background-color: #6AA543;
    outline: none;
}

.col-header-seach .cate-search:focus svg path {
    fill: #fff;
}

.col-header-seach input.form-control.search-query {
    padding: 12px 50px 12px 15px !important;
    font-size: 14px;
    background: #F5F5F7;
    height: auto;
    border: 0 !important;
    width: 100%;
}

.header .search-form .btn-search svg {
    display: inline-block;
}

.header .search-form {
    border: 0;
}

.header .search-form .btn-search {
    border-radius: 0 8px 8px 0;
    position: absolute;
    right: 0;
    padding: 0 !important;
    width: 82px;
    height: 40px;
    top: 0;
    line-height: 1;
	margin: 0;
}

.col-header-seach input.form-control.search-query:focus {
    box-shadow: none;
}

.col-header-seach .dropdown-item {
    color: #17181C;
    font-size: 14px;
}

.header-search-form i.site-description {
    font-size: small;
}

.col-header-seach .cate-search option {
    background-color: #fff;
}

.list-keyword-search {
    margin: 6px 0 0 0;
}

.list-keyword-search ul {
    list-style: none;
    padding: 0;
    margin: 0 -10px;
}

.list-keyword-search ul li {
    padding: 0 10px;
    display: inline-block;
    border-right: 1px solid #C0C3CB;
    line-height: 1;
}

.list-keyword-search ul li a {
    color: #818898;
    font-size: 12px;
    font-weight: 400;
}

.list-keyword-search ul li:last-child {
	border-right: none;
}

.header-transport-wishlist-minicart>ul.nav {
    z-index: 1000;
}

.header-transport-wishlist-minicart ul li {
    padding: 0 8px;
}

.header-transport-wishlist-minicart ul.shadow-menu-mobile li {
    padding: 0 5px;
}

/* .header-transport-wishlist-minicart ul li:last-child {
    padding-right: 0;
} */

.header-transport-wishlist-minicart ul li a,
.header-transport-wishlist-minicart ul li .btn-wishlist-header,
.header-transport-wishlist-minicart ul li .btn-minicart-header {
    display: flex;
    display: -ms-flexbox;
    position: relative;
}

.header-transport-wishlist-minicart ul li a,
.header-transport-wishlist-minicart ul li .btn-wishlist-header,
.header-transport-wishlist-minicart ul li .btn-minicart-header {
    font-size: 12px;
    color: #5C6370;
}

.header-transport-wishlist-minicart ul li a
.header-transport-wishlist-minicart ul li .btn-wishlist-header,.count,
.header-transport-wishlist-minicart ul li .btn-minicart-header .count {
    display: block;
    font-weight: 400;
    color: #fff;
    border-radius: 100px;
    padding: 4px 7px;
    line-height: 1;
    position: absolute;
    left: 25px;
    top: -2px;
    text-align: center;
}

.header-transport-wishlist-minicart ul li .btn-minicart-header:hover svg path {
    fill: #67A729;
}

.header-transport-wishlist-minicart ul li a.btn-notification-header .count {
	left: 20px;
}

.header-transport-wishlist-minicart ul li .btn-minicart-header .count.custom {
    top: 6px;
    left: 28px;

    @media(max-width: 1024px) {
        top: 16px;
    }
}

.header-transport-wishlist-minicart ul li a.btn-notification-header .count.custom {
	top: 4px;
    left: 28px;
}

.header-transport-wishlist-minicart ul li a.btn-notification-header:hover svg path {
	stroke: #67A729;
}

.btn-notification-header .count {
    min-width: none;
    background-color: #FF1D30;
}

.btn-wishlist-header .count {
    min-width: none;
    background-color: #FFA829;
}

.btn-minicart-header .count {
    background-color: #017BFF;
}

/*
@media (min-width: 992px) {
    .header .logo {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .header .header-search-form {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .header .header-transport-wishlist-minicart {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
} */

#header-main-menu {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
}

/* #header-main-menu ul.main-menu>li {
    padding: 0 10px;
} */

#header-main-menu ul.main-menu>li>a {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    font-weight: 500;
}

#header-main-menu ul.main-menu-mobile>li>a.text-accent-fill svg path {
    fill: #818898;
}

.text-accent-stroke svg path {
    stroke: #818898;
}

.text-accent-fill svg path {
    fill: #818898;
}

#header-main-menu ul.main-menu-mobile>li>a svg {
    margin-right: 8px;
}

.grid-submegamenu {
    padding: 0 10px;
}

.grid-submegamenu li {
    padding: 5px 10px;
}

.grid-submegamenu li a {
    font-size: 14px;
    color: #17181C;
}

.popover--submenu {
    width: 36rem;
}

.dropdown-megamenu {
    cursor: pointer;
}

.dropdown-megamenu:after {
    display: inline-block;
    margin-left: .35em;
    vertical-align: middle;
    content: "";
    border-top: .35em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.popover--submenu.hide {
    display: none;
}

.popover--submenu.show {
    display: block;
}

/*** Footer ***/
footer#footer {
    background-color: #ffffff;
}

.container-small {
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

@media(max-width: 768px) {
    .container-small {
        max-width: 768px;
    }
}


.footer-top .col-item-footer-top {
    text-align: center;
    padding: 0 15px;
    position: relative;
}

.col-item-footer-top svg {
    display: inline-block;
}

.footer-top {
    padding: 50px 0 30px 0;
}

.footer-top .col-item-footer-top h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #17181C;
    max-width: 135px;
    width: 100%;
    margin: 0 auto;
}

.footer-top .col-item-footer-top .image {
    position: relative;
    min-height: 65px;
    margin-bottom: 15px;
}

.footer-top .col-item-footer-top:after {
    content: '';
    display: block;
    width: 1px;
    height: 35px;
    background-color: #D5D7DD;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
}

.footer-top .col-item-footer-top:last-child:after {
    display: none;
}

.footer-top .col-item-footer-top .image .circle {
    background: linear-gradient(180deg, #90C26E 0%, #49A32A 100%);
    opacity: 0.2;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 30%;
    top: 38%;
}

.footer-top .col-item-footer-top:nth-child(2) .image .circle {
    left: 57%;
    top: -18%;
}

.footer-top .col-item-footer-top:nth-child(3) .image .circle {
    left: 57%;
    top: 15%;
}

.footer-top .col-item-footer-top:nth-child(4) .image .circle {
    left: 54%;
    top: -18%;
}

.footer-top .col-item-footer-top:nth-child(5) .image .circle {
    left: 30%;
    top: 66%;
}

.box-footer-address {
    background: #6AA543;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 35px;
}

.col--footer-address {
    border-right: 1px solid #D5D7DD;
    border-top: 1px solid #D5D7DD;
    padding: 32px 20px;
    display: flex;
    align-items: center;
}

.col--footer-address:last-child {
    border-right: 0;
}

.col--footer-address:nth-child(1),
.col--footer-address:nth-child(2),
.col--footer-address:nth-child(3) {
    border-top: 0;
}

.footer-bottom .fb_iframe_widget {
    width: 100%;
}

.footer-bottom .fb_iframe_widget span,
.footer-bottom .fb_iframe_widget iframe {
    width: 100% !important;
}

.footer-bottom .col--footer-bottom {
    padding: 0 15px;
}

@media(min-width: 1500px) {
    .footer-bottom .col--footer-bottom:last-child {
        padding-left: 52px;
    }
}

.footer-copyright {
    padding: 15px;
    background-color: #243E85;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.footer-bottom {
    padding: 20px 0;
}

.footer-bottom ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.footer-bottom ul li a {
    color: #17181C;
    font-size: 16px;
}

.footer-bottom ul li {
    margin-bottom: 8px;
}

.footer-bottom .row {
    margin-left: -15px !important;
    margin-right: -15px !important;
}

.gl-social-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 0;
    clear: both;
}

.footer-bottom .col--footer-bottom iframe {
    float: right;
}

.col--footer-social {
    display: flex;
    align-items: center;
}

.gl-social-footer .info a {
    color: #17181C;
    font-size: 14px;
    line-height: 1;
}

.gl-social-footer .icon-img {
    margin-right: 12px;
}

.box-footer-address .icon-img {
    margin-right: 20px;
}

.box-footer-address .info h4 {
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    margin: 0 0 5px 0;
}

.box-footer-address .icon-img svg path {
    stroke: #ffffff;
}

.box-footer-address .info p {
    color: #ffffff;
    font-size: 14px;
    margin: 0;
}

.box-footer-address .info h4 a {
    color: #ffffff;
}

.footer-address {
    position: relative;
    z-index: 9;
}

.footer-bottom .flex {
    margin-left: -15px;
    margin-right: -15px;
}

.footer-bottom ul li a:hover {
    color: #6AA543;
}

.gl-social-footer .icon-img div {
    display: block !important;
}

/*** Style Homepage ***/
.top-list-shops-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.top-list-shops-new h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.top-list-shops-new h3 span,
.top-list-shops-new a span {
    margin-right: 0px;
}

.top-list-shops-new a span:hover {
    color: rgba(var(--color-accent-hover));
}

.top-list-shops-new a {
    display: flex;
    align-items: center;
    color: #6AA543;
    font-size: 14px;
    font-weight: 600;
}

.bg-gray-full {
    background-color: #FAFAFA;
}

.popup-content-product .bg-gray-full {
    background-color: #ffffff;
}

.box-shops-homepage {
    background-color: #fff;
    border: 1px solid transparent;
}

.box-shops-homepage:hover {
    border: 1px solid #6AA543;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.box-shops-homepage .img {
    position: relative;
}

.box-shops-homepage .img .actions {
    position: absolute;
    top: 16px;
    right: 12px;
}

.box-shops-homepage .actions .btn {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    cursor: pointer;
}

.box-shops-homepage .actions .btn:last-child {
    margin-bottom: 0;
}

.box-shops-homepage .img>a div {
    display: block !important;
    border-radius: 8px 8px 0 0;
}

.box-shops-homepage .img>a .shop-expired {
    display: flex!important;
}

.box-shops-homepage .info {
    padding: 12px;
}

.box-shops-homepage .info ul.list-tags {
    display: flex;
    flex-wrap: wrap;
}

.box-shops-homepage .info ul.list-tags li {
    margin: 0 8px 8px 0;
}

.box-shops-homepage .info ul.list-tags li a {
    background: rgba(106, 165, 67, 0.1);
    border-radius: 8px;
    font-size: 12px;
    color: #6AA543;
    display: block;
    padding: 2px 6px;
}

.box-shops-homepage .info h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #17181C;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 40px;
    margin-bottom: 4px;
}

.box-shops-homepage .info h4 a:hover {
    color: #6AA543;
}

.box-shops-homepage .info .date,
.txt-bottom-box-shop {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C6370;
}

.box-shops-homepage .info .date,
.txt-bottom-box-shop strong {
    font-weight: 600;
    color: #243E85 !important;
}

.box-shops-homepage .info .date strong,
.txt-bottom-box-shop strong {
    color: #000;
}

.btn-swiperslide svg path {
    fill: #6AA543;
}

.btn-swiperslide:hover svg path {
    fill: #ffffff;
}

.activeWishlist svg path {
    stroke: #FF1D30;
    fill: #FF1D30;
}

.popover--transport {
    background-color: #6AA543;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 15px;
    max-width: 200px;
}

.popover--transport span {
    font-weight: 600;
}

.popover--transport:before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #6AA543;
    position: absolute;
    top: -5px;
    right: 9px;
}

.activeTransport-shop .popover--transport {
    background-color: #017BFF;
}

.activeTransport-shop .popover--transport:before {
    border-bottom: 5px solid #017BFF;
}

.activeTransport-shop svg path {
    fill: #017BFF;
    stroke: #017BFF;
}

.activeTransport-i33 svg path {
    fill: #6AA543;
    stroke: #6AA543;
}

.carousel-cell {
    width: 20%;
    margin-right: 0px;
    float: left;
    padding: 6px;
}

.product-box {
    background-color: #ffffff;
    padding-bottom: 16px;
    box-shadow: 0 0 0 0 rgb(23 27 30 / 0%);
    transition: all .3s ease-in-out;
    position: relative;
    z-index: 1;
    border-radius: 8px;
    border: 1px solid transparent;
}

.product-box:hover {
    border: 1px solid #6AA543;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.product-box:hover .product-link img {
    width: 180px;
    height: auto;
}

.slick-product-carousel-one .carousel-cell {
    width: 25%;
}

.product-box-image {
    height: 192px;
    border: none;
    text-align: center;
    background-color: #fff;
    overflow: hidden;
    padding: 16px 16px 0;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media (max-width: 640px) {
        height: auto;
    }
}

.product-box-info {
    background-color: #fff;
    margin-top: 0;
    padding: 12px 12px 0;
    text-align: center;
}

.product-labels {
    position: absolute;
    z-index: 2;
}

.label-in-cart {
    background-color: rgba(68, 114, 5, 0.8);
    padding: 3px;
    border-radius: 3px;
    color: white;
    text-align: center;
    display: block;
    font-size: 10px;
}

.product-link {
    color: #17181C;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    text-decoration: none;
    min-height: 41px;
    display: inline-block;
}

.product-options-list .swatch .select-swatch-label {
    color: #67A729;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 14px;
}

.product-options-list .swatch .select-swatch-label.out-stock {
    color: #5C6370;
}

.product-options-list .swatch-option-value {
    position: absolute;
    -webkit-transform: none;
    transform: none;
    perspective: none;
    background: transparent;
    bottom: auto;
    top: 46%;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    visibility: hidden;
}

.product-options-list .gl-product-selection {
    color: #67A729;
    font-weight: 700;
}

.product-options-list .gl-product-selection.out-stock {
    color: #5C6370!important;
}

.product-options-list ul li {
    border-radius: 4px;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0);
    padding: 0 1px;
}

.product-options-list ul label {
    margin: 0;
}

.product-options-list ul li label a {
    display: block;
    padding: 5px 8px;
    border: 1px solid #E1E1E1;
    color: #212121;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    border-radius: 10px;
    min-width: 30px;
}

.product-options-list ul li:hover label a {
    border-color: #6AA543;
    color: #fff !important;
    background-color: #6AA543;
}

.product-box-image:hover .product-options-list .swatch-option-value {
    opacity: 1;
    visibility: visible;
}

.gl-options-variants-selected:hover .product-link img {
    opacity: 0.2 !important;
}


.form--wishlist {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.form--wishlist.promotion-overide {
    right: 0px;
}

.form--wishlist .btn {
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

.form--wishlist .btn:hover svg path {
    fill: #FF1D30;
    stroke: #FF1D30;
}

.product-item-sku {
    color: #5C6370;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.product-item--store small {
    font-size: 12px;
    color: #6AA543;
    line-height: 16px;
    font-weight: 600;
}

.product-item--store.out-stock small {
    font-size: 12px;
    color: #5C6370;
    line-height: 16px;
    font-weight: 600;
}

.product-item--store.out-stock small:hover {
    color: #5C6370;
}

.product-item--store small:hover {
    color: #67A729;
}

.product-box-info .name-store,
.product-box-info .sku {
    font-size: 14px;
}

.product-info {
    margin-bottom: 0;
    padding-bottom: 5px;
}

.product-card .product-info .final-price {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

.product-info .final-price {
    font-weight: 600;
    font-size: 40px;
    color: #17181C;
    line-height: 40px;
    margin-bottom: 4px;

    @media (max-width: 640px) {
        font-size: 24px;
    }
}

.product-info .original-price {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17181C;
}

.product-options-and-addtocart {
    width: 100%;
    display: flex;
    justify-content: center;
}

.product-item--quantity-group {
    margin-bottom: 0;
    text-align: center;
}

.product-item--quantity-group .form-Add-to-cart {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.product-item--quantity-group .input-group {
    width: auto;
    display: flex;
    align-items: center;
    flex-wrap: inherit;
    justify-content: center;
    margin-right: 8px;
}

button.btn-addtocart-product-item {
    font-size: 12px;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 8px;
    font-weight: 600;
    max-width: 102px;
}


button.btn-addtocart-product-item svg {
    margin-right: 6px;
}

.product-item--quantity-group .input--quantity {
    font-size: 14px;
    width: 100%;
    min-width: 40px;
    max-width: 40px;
    height: 32px;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0 1px;
}

.product-box-info .btn--minus,
.product-box-info .btn--plus {
    width: 32px;
    height: 32px;
    font-size: inherit;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.product-box-info  .input--quantity input {
    padding: 0;
    border: 0;
    background-color: #fff;
    height: 32px;
    text-align: center;
    box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
.product-options-and-addtocart input::-webkit-outer-spin-button,
.product-options-and-addtocart input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.product-options-and-addtocart input[type=number] {
  -moz-appearance: textfield;
}

.product-box-info .product-link {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-item--store a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.label-hot, .label-sale, .label-best {
    margin: 0 0 5px 0;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
}

.label-hot {
    background-color: #FF1D30;
    border-color: #FF1D30;
}

.label-sale {
    background-color: #FFA829;
    border-color: #FFA829;
}

.label-best {
    background-color: #017BFF;
    border-color: #017BFF;
}

.final-price.text-danger {
    color: #FF1D30;
    margin-right: 5px;
}

.product-info s {
    text-decoration: none;
    position: relative;
}

.product-info s:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: red;
    -webkit-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    -ms-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate(-7deg);
}

.row-products {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

@media(min-width: 768px) {
    .left-home-product-seller {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 10px;
        position: relative;
    }

    .right-home-product-seller {
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        max-width: 80%;
        position: relative;
    }
}

.left-home-product-seller img {
    padding: 0 10px 0 0 !important;
}

.slick-product-carousel-one {
    margin-top: -10px;
    margin-bottom: -10px;
}

button.slick-arrow {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff !important;
    opacity: 1;
    z-index: 9;
}

button.slick-next:before {
    content: '';
    background-image: url('../img/icon-arrow-next.svg') !important;
    width: 11px;
    height: 20px;
    background-size: contain;
    position: absolute;
    opacity: 1;
    top: 10px;
    left: 15px;
}

button.slick-prev:before {
    content: '';
    background-image: url('../img/icon-arrow-next.svg') !important;
    width: 11px;
    height: 20px;
    background-size: contain;
    position: absolute;
    top: 10px;
    left: 12px;
    -ms-transform: rotate(20deg);
    transform: rotate(180deg);
}

button.slick-arrow.slick-next {
    right: -12px;
}

button.slick-arrow.slick-prev {
    left: -12px;
}

.item-promotions-homepage .img {
    position: relative;
}

.item-promotions-homepage .img .btn {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
}

.item-promotions-homepage .info {
    background-color: #fff;
    padding: 12px;
}

.item-promotions-homepage .info h4 {
    margin-bottom: 15px;
}

.item-promotions-homepage .info a {
    font-size: 16px;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
	margin-bottom: 12px;
}

.countdown-timer-3days {
    background-color: #EB5355;
    color: #fff;
    font-size: 12px;
    text-align: center;
    padding: 4px 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.item-promotions-homepage .img a div {
    display: block !important;
}

.item-promotions-homepage .img .expire-promotion {
    display: flex !important;
}

.item-promotions-homepage .img .expire-promotion.wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.countdown-timer {
    background-color: #E5E7EB;
    border-radius: 10px;
    height: 5px;
    width: 100%;
    position: relative;
    margin-bottom: 8px;
}

.countdown-timer.expired {
    background-color: #D1D0D9;
}


.wct--countdown {
    background: #FFA829;
    border-radius: 10px;
    height: 5px;
}

.date-promotions {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5C6370;
}

.timmer--3days {
    display: flex;
    align-items: center;
    color: #FF1D30;
}

.timmer--3days svg {
    margin-right: 5px;
}

.wct--countdown.wct--countdown-3days {
    background-color: #FF1D30;
}

.slick-product-carousel-two,
.row-list-promotions-homepage {
    margin-left: -10px;
    margin-right: -10px;
}

.row-list-promotions-homepage .slick-slide {
    padding: 0 10px;
}

.slick-slider .slick-dots li button {
    background-color: #C4C4C4;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
}

.slick-slider ul.slick-dots li.slick-active button,
.slick-slider ul.slick-dots li button:hover {
    background-color: #6AA543;
}

.slick-dots li button:before {
    display: none !important;
}

.aside-extra-menu--categories {
    position: fixed;
    z-index: 1500;
    left: -1500px;
    visibility: hidden;
    opacity: 0;
    transition: all 400ms ease-out;
    transform: translate(-200px, 0);
    -webkit-transform: translate(-200px, 0);
    background-color: #ffffff;
    color: #707070;
    box-shadow: 0px 0px 60px 0px rgb(41 44 58 / 6%);
}

.aside-extra-menu--categories.open-aside-extra-menu {
    visibility: visible;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}

.la-overlay-global {
    position: fixed;
    z-index: 1200;
    visibility: hidden;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    transition: all 250ms ease-out;
    background-color: #17181C;
    opacity: 0.4;
}

.la-overlay-global.la-overlay-global--active {
    visibility: visible;
}

.list-menu--categories .title {
    background-color: #6AA543;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-family: 'Be Vietnam Pro'!important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

#tabs-menu-category .nav-item {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 24px;
    justify-content: space-between;
}

#tabs-menu-category .nav-item a.nav-link {
    display: flex;
    align-items: center;
    color: #17181C;
    font-size: 16px;
    font-weight: 600;
}

#tabs-menu-category .nav-item a.nav-link svg {
    margin-right: 15px;
    width: 26px;
    height: auto;
}

.list-menu--categories {
    height: 100%;
    position: relative;
}

#tabs-menu-category {
    height: calc(100% - 60px);
    overflow: auto;
}

#tabs-menu-category .nav-item a.nav-link:hover,
#tabs-menu-category .nav-item a.nav-link.text-accent,
.category-header:hover {
    color: #6AA543;
}

#tabs-menu-category .nav-item a.nav-link:hover svg path,
#tabs-menu-category .nav-item a.nav-link.text-accent svg path {
    fill: #6AA543;
}

.category-header:hover svg path {
    stroke: #6AA543;
}

.cursor-pointer {
    cursor: pointer;
}

#content-menu-category-pc {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    height: 100%;
}

#content-menu-category-pc .collapse {
    display: none;
}

#content-menu-category-pc.tab-active {
    visibility: visible;
    opacity: 1;
}

.content-submenu-dm-pc>a {
    font-size: 16px;
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    padding: 14px 24px;
    font-weight: 600;
    color: #17181C;
}

.content-submenu-dm-pc>a svg {
    margin-right: 15px;
}

.content-submenu-dm-pc>.parent-header {
    font-size: 16px;
    background-color: #E5E7EB;
    display: flex;
    align-items: center;
    padding: 14px 24px;
    font-weight: 600;
    color: #17181C;
}

.content-submenu-dm-pc>.parent-header svg {
    margin-right: 15px;
}

.row-child-dm--pc {
    overflow: auto;
    height: calc(100vh - 112px);
}

.row-child-dm--pc-mobile {
    overflow: auto;
    height: calc(100vh - 200px);
}

.row-child-dm--pc ul>li {
    padding: 14px 24px;
    border-bottom: 1px solid #E5E7EB;
    background-color: #fff;
}

.row-child-dm--pc ul>li a {
    color: #17181C;
    font-size: 14px;
}


.row-child-dm--pc ul>li:hover,
.row-child-dm--pc ul>li.text-accent {
    background-color: #F0F6EC;
}

/*** Popup Product ***/
.breadcrumbs ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 14px;
    align-items: center;
    padding: 16px 0;
}

.popup-content-product .breadcrumbs ul {
    padding: 12px 24px;
    border: 1px solid #EDEDED;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.breadcrumbs ul li {
    padding: 0 5px;
    color: #17181C;
}

.breadcrumbs ul li a {
    color: #5C6370;
}

.breadcrumbs ul li a:hover {
    color: #6AA543;
}

.related-product {
    background-color: #FAFAFA;
}

@media(min-width: 768px) {
    .related-product {
        -webkit-border-bottom-right-radius: 0.75rem;
        -webkit-border-bottom-left-radius: 0.75rem;
        -moz-border-radius-bottomright: 0.75rem;
        -moz-border-radius-bottomleft: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
    }
}

.product--description-below h2 {
    background-color: #FAFAFA;
    border-radius: 8px;
    padding: 16px 24px;
    text-transform: uppercase;
    font-size: 20px;
    color: #000;
    margin-bottom: 16px;
}

p.text-body {
    color: #17181C;
}

.product--description-below .text-body {
    padding: 0 24px;
}

.related-product .btn-tab-related-product {
    color: #17181C;
    font-size: 16px;
    font-weight: 400;
    margin-right: 16px;
    padding: 8px 20px;
    line-height: 24px;
    border-radius: 100px;
    border: 1px solid #EDEDED;
    background: #FFF;

    @media (max-width: 640px) {
        font-size: 12px;
        font-weight: 500;
    }

}

.related-product .btn-tab-related-product:last-child {
    margin-right: 0;
}

.related-product .btn-tab-related-product:hover,
.related-product .btn-tab-related-product.active {
    background-color: #6AA543;
    color: #fff;
    font-weight: 600;
}

.related-product div[role="tablist"] {
    margin-bottom: 16px;
}

.btn-view-shop-detail {
    color: #6AA543;
    font-size: 14px;
    margin-bottom: 8px;
}

.title-and-wishlist h1 {
    color: #000;
}

.title-and-wishlist {
    position: relative;
    padding-right: 75px;
    margin-bottom: 16px;

    @media (max-width: 640px) {
        padding-right: 30px;
        margin-bottom: 12px;
    }
}

.btn-add-wishlist-product-detail {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
}

.product-description h5.product-sku {
    font-size: 14px;
    color: #5C6370;
}

.product-description h5.product-sku span {
    color: #17181C;
}

.product-description .price-sp {
    background-color: #FAFAFA;
    border-radius: 8px;
}

.product-description .final-price.text-danger {
    font-size: 40px;
    font-weight: 600;
    margin-right: 15px;

    @media (max-width: 640px) {
        font-size: 24px;
    }
}

.product-description .final-price.text-danger .padPrice {
    font-size: 26px;
    margin-right: 10px;

    @media (max-width: 640px) {
        font-size: 14px;
    }
}

.product-description .product-info .original-price {
    font-size: 20px;
    color: #818898;
    font-weight: 600;
    line-height: 24px;

    @media (max-width: 640px) {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.product-description .product-info s:before {
    display: none;
}

.product-description .product-info s {
    text-decoration: line-through;
    margin-right: 20px;
}

.product-description span.original-price.price--regular {
    display: block;
    margin-top: -5px;
}

.sale-percentage {
    background: linear-gradient(90deg, #FF9905 -3.95%, #FFC571 100%);
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 4px 16px;
    display: inline-flex;
    align-items: center;

    @media (max-width: 640px) {
        font-size: 12px;
        padding: 4px 12px;
    }
}

.product-description h4 {
    font-weight: 600;
    font-size: 16px;
    color: #17181C;
}

.product-options .swatch>label {
    color: #5C6370;
    font-size: 16px;
    margin-bottom: 8px;
    display: block;

    @media (max-width: 640px) {
        font-size: 14px;
    }
}
.product--quantity>label {
    color: #5C6370;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
    display: block;

    @media (max-width: 640px) {
        font-size: 14px;
    }
}

.product-options .swatch ul {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.product-options .swatch ul li input {
    display: none;
}

.product-options .swatch ul li label {
    display: block;
    cursor: pointer;
    position: relative;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 12px;
    min-width: 33px;
    text-align: center;
}

.product-options .swatch ul li.swatch-item-color label {
    border: 1px solid #E5E7EB;
    border-radius: 10px;
    padding: 0;
}

.product-options .swatch ul li.swatch-item-color label img {
    border-radius: 10px;
}

.product-options .swatch ul li label:hover,
.product-options .swatch ul li input:checked+label {
    border-color: #67A729;
}

.product-options .swatch ul li.swatch-item-color label div {
    display: block !important;
}

.please-select-option {
    color: #E80606;
    max-width: 405px;
    width: 100%;
}

.product-detail .product--quantity {
    margin-bottom: 20px;

    @media (max-width: 640px) {
        margin-bottom: 16px;
    }
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.product--quantity .btn--minus,
.product--quantity .btn--plus {
    padding: 12px;
    font-size: inherit;
    border: 1px solid #D5D7DD;
    border-radius: 0;
    background: #ffffff;
}

.product--quantity .btn--minus:focus,
.product--quantity .btn--plus:focus {
    border: 1px solid #67A729;
}


.product--quantity .btn--minus {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.product--quantity .btn--plus {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.product--quantity .input--quantity input {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #000;
    height: 44px;
    border: 0;
    min-width: 80px;
    max-width: 80px;
    border: 0;
    background-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.product--quantity .input--quantity input::-webkit-outer-spin-button,
.product--quantity .input--quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.product--quantity .input--quantity input[type=number] {
  -moz-appearance: textfield;
}

.product--quantity {
    margin-bottom: 10px;
}

.product-description .btn-Addtocart {
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    line-height: 20px;
    width: 230px;

    @media (max-width: 640px) {
        width: 100%;
    }
}

.product-description .price-sp .product-info s:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: red;
    -webkit-transform: rotate( -7deg);
    -moz-transform: rotate(-7deg);
    -ms-transform: rotate(-7deg);
    -o-transform: rotate(-7deg);
    transform: rotate( -7deg);
}

.product-options .swatch ul li label:hover:before,
.product-options .swatch ul li input:checked+label:before {
    content: '';
    display: block;
    background: #6AA543;
    border: 1px solid #6AA543;
    box-sizing: border-box;
    border-radius: 0px 7px 0px 5px;
    width: 22px;
    height: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
}

.product-options .swatch ul li label:hover:after,
.product-options .swatch ul li input:checked+label:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 5;
}

#productGalleryThumbs .swiper-slide {
    border-width: 1px;
    border-radius: 10px;
}

#productGallery {
    overflow: unset;
}

.Portal-SwiperSlide {
    /* position: absolute; */
    left: 102%;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
}

.Portal-SwiperSlide img {
    max-width: unset;
}

.product-gallery {
    position: relative;
}

/*** Sidebar Minicart ***/
.title-sidebar-minicart {
    background-color: #6AA543;
    color: #fff;
}

.title-sidebar-minicart .actions {
    display: flex;
    align-items: center;
    position: relative;
}

.no-products-minicart {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 15px;
}

.no-products-minicart h4 {
    color: rgba(23, 24, 28, 1);
    margin-bottom: 4px;
    max-width: 300px;
}

.no-products-minicart p {
    color: rgba(92, 99, 112, 1);
    font-size: 14px;
}

.no-products-minicart .btn {
    border-radius: 8px;
    color: #fff;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    max-width: 154px;
    width: 100%;
}

.main-sidebar-minicart {
    background-color: #FAFAFA;
    padding-left: 15px;
    padding-right: 15px;
}

.list-group-store-minicar {
    background-color: #ffffff;
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid rgba(237, 237, 237, 1);
    border-radius: 8px;
    position: relative;
}

.list-group-store-minicar.border-active {
    border: 1px solid #6AA543;
}

.top-minicar-store {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-bottom: 8px;
}

.top-minicar-store .name-and-number .name {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    font-size: 16px;
}

.top-minicar-store .price {
    text-align: right;
}

.top-minicar-store .minicart-store-price {
    font-size: 14px;
    font-weight: 500;
    display: block;
}

.top-minicar-store .name-and-number {
    padding: 0 15px 0 0;
}

.bez--dph {
    font-size: 13px;
    color: #818898;
    font-weight: 400;
}

.minimum-order-notice {
    background: rgba(255, 168, 41, 0.05);
    border-radius: 8px;
    display: flex;
    padding: 3px 15px;
    align-items: center;
    justify-content: center;
    color: #FFA829;
    font-size: 14px;
}

.minimum-order-notice svg {
    margin-right: 5px;
}

.selected-number-itemt-minicart {
    color: #818898;
    font-weight: 600;
    font-size: 14px;
}

.list-items-minicart .flex-item-minicart:last-child {
    border: 0;
}

.list-items-minicart .flex--quantity {
    width: auto;
    height: auto;
    background-color: #fff;
    border-radius: 0;
    flex-direction: row;
}

.list-items-minicart .text-price {
    font-size: 14px;
    font-weight: 500;
}

.flex-item-minicart {
    position: relative;
}

.flex-item-minicart .btn-remove-item-minicart {
    position: absolute;
    top: 0;
    right: 12px;
}

.flex-item-minicart .flex--quantity {
    margin-right: 15px;
}

.flex-item-minicart .flex--quantity button {
    border: 1px solid #E5E7EB;
    width: 34px;
    height: 34px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.flex-item-minicart .flex--quantity button svg path {
    stroke: #6AA543;
}

.flex-group-qty-price {
    display: flex;
    align-items: center;
}

.flex-item-minicart .input--qty {
    width: 50px;
    padding: 0 5px;
}

.card--footer {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
}

.btn-view-cart,
.btn-defautl-minicart {
    background-color: #6AA543;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    display: block;
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.row-totalPrice-minicart {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
    text-align: right;
}

.row-totalPrice-minicart .priceTotal {
    font-size: 16px;
    display: block;
}

.row-totalPrice-minicart small {
    display: block;
    color: #818898;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
}

#modalDeleteItemsMiniCart {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
    border-radius: 10px;
    z-index: 9;
    min-width: 200px;
    padding: 15px;
    right: 0;
    top: 44px;
}

#modalDeleteItemsMiniCart:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    right: 38px;
}

#modalDeleteItemsMiniCart button {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D5D7DD;
    padding-bottom: 12px;
    margin: 0 0 12px 0;
    color: #17181C;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
}

#modalDeleteItemsMiniCart button:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

#modalDeleteItemsMiniCart button svg {
    margin-right: 10px;
}

#modalDeleteItemsMiniCart button:hover {
    color: #67A729;
}

.minicart-checkbox-store .checkbox_checkbox__3Pae_ + label:before {
    border: 0;
    width: 25px;
    height: 25px;
    background-color: #cdcdcd;
}

.minicart-checkbox-store .checkbox_checkbox__3Pae_:checked + label:before {
    background-color: #6AA543;
}

.minicart-checkbox-store .checkbox_checkbox__3Pae_:checked + label:after {
    left: 7px;
    top: 11px;
}

.list-group-store-minicar.active {
    margin-left: 30px;
    padding-bottom: 12px;
}

.minicart-checkbox-store,
.box-checkbox-store {
    position: absolute;
    top: 35%;
    transform: translate(-50%, -50%);
    left: -35px;
}

.minicart-view-all-products-store {
    color: #6AA543;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
}

.list-items-minicart.collapsed {
    display: none;
}

button.btn-deleteMiniCartStore.hide {
    display: none !important;
}

.footer-minicart-delete-to-store h4 {
    font-weight: 400;
    font-size: 16px;
    color: #17181C;
    margin: 0 0 12px 0;
}

.footer-minicart-delete-all-store {
    text-align: center;
}

.footer-minicart-delete-all-store svg {
    display: inline-block;
    margin-bottom: 15px;
}

.footer-minicart-delete-all-store h4 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 12px 0;
}

.footer-minicart-delete-all-store p {
    font-size: 16px;
    color: #818898;
    margin-bottom: 20px;
}

button.back-to-minicart {
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    border: 1px solid transparent;
    border-color: #6AA543;
    color: #6AA543;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 10px;
}

button.back-to-minicart:hover {
    background-color: #6AA543;
    color: #ffffff;
}

button.btn-delete-all-store-minicart {
    display: block;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    border: 1px solid transparent;
    border-color: #FF1D30;
    background-color: #FF1D30;
    padding: 12px;
    border-radius: 8px;
    color: #ffffff;
}

.box-checkbox-store input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.box-checkbox-store .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid #D5D7DD;
}

.box-checkbox-store input:checked ~ .checkmark {
    background-color: #6AA543;
    border: none;
}

.box-checkbox-store .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.box-checkbox-store input:checked ~ .checkmark:after {
    display: block;
}

.order-select-active svg path {
    stroke: rgba(var(--color-light));
}

.product-template-category {
    overflow: auto;
    height: calc(100vh - 280px);
}

.insidebar-sidebar {
    overflow: auto;
    height: calc(100vh - 350px);
}

.product-template-category #tabs-menu-category,
.shop-template-category #tabs-menu-category {
    height: 100%;
    overflow: auto;
}

.product-template-category #content-menu-category-pc,
.shop-template-category #content-menu-category-pc {
    top: 57px
}

/*** Product Detail ***/
.template-product-detail {
    background-color: #FAFAFA;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.right-product-detail--shop ul li {
    display: flex;
    align-items: center;
    padding: 3px 0;
}

.right-product-detail--shop ul li svg {
    margin-right: 10px;
}

.right-product-detail--shop ul li span {
    color: #017BFF;
}

.left-product-detail--shop {
    border-right: 1px solid #E5E7EB;
}

.left-product-detail--shop .image-and-name:after {
    content: '';
    display: table;
    clear: both;
}

.left-product-detail--shop .image-and-name .image {
    width: 70px;
    float: left;
}

.left-product-detail--shop .image-and-name .name {
    width: 100%;
}

.left-product-detail--shop h3 {
    font-weight: 600;
    font-size: 16px;
}

.left-product-detail--shop .image-and-name .name p {
    font-size: 12px;
    color: #818898;
}

.left-product-detail--shop .email-and-phone {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
}

.left-product-detail--shop .email-and-phone a,
.left-product-detail--shop .email-and-phone .label {
    font-weight: 600;
    color: #6AA543;
    border: 1px solid transparent;
    border-color: #6AA543;
    padding: 7px 12px;
    border-radius: 8px;
    cursor: pointer;
}

.left-product-detail--shop .email-and-phone a svg,
.left-product-detail--shop .email-and-phone .label svg:first-child {
    margin-right: 10px;
}

.left-product-detail--shop .image-and-name .image img {
    border-radius: 50%;
}

.left-product-detail--shop .email-and-phone>div {
    padding: 0 5px;
    position: relative;
}

.left-product-detail--shop .email-and-phone li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-product-detail--shop .email-and-phone .text-content {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.left-product-detail--shop .dropdown-phone-number {
    position: absolute;
    top: 100%;
    right: 5px;
    background-color: #fff;
    z-index: 10;
    left: 5px;
}

.left-product-detail--shop .dropdown-phone-number ul {
    padding: 6px 0;
}

.left-product-detail--shop .dropdown-phone-number ul li {
    padding: 6px;
    text-align: center;
    font-weight: 600;
    color: #6AA543;
}

.popup-content-product .product-detail--shop {
    display: none;
}
/*** Add to cart ***/
.list-items-minicart h3.text-heading {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-right: 20px;
}

/*** Page Cart ***/
.cart-related-products h3 {
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.cart-top-step {
    padding: 16px 0;
}

.cart-top-step:after,
.content-cart-top-step:after,
.progressbar:after {
    content: '';
    display: table;
    clear: both;
}

.content-cart-top-step {
    width: 100%;
    max-width: 860px;
    margin: 0 auto;
    text-align: center;
    color: #818898;
    font-size: 16px;
    font-weight: 600;
    position: relative;
}

.content-cart-top-step .progressbar {
    counter-reset: step;
    padding: 0;
  }

  .content-cart-top-step .progressbar li {
    float: left;
    list-style: none;
    position: relative;
    text-align: center;
    width: calc(100% / 3);
    font-family: 'Be Vietnam Pro'!important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.content-cart-top-step .progressbar li:before {
    content: counter(step);
    counter-increment: step;
    font-family: 'Be Vietnam Pro'!important;
    font-size: 16px;
    width: 40px;
    height: 40px;
    padding: 8px;
    display: inline-block;
    border: 1px solid var(--neutral-divider-border, #EDEDED);
    background: var(--neutral-white, #FFF);
    border-radius: 8px;
    color: var(--neutral-maintext, #17181C);
    font-weight: 400;
    margin: 0 auto;
    margin-right: 12px;
    z-index: 3;
    position: relative;
}

.content-cart-top-step .progressbar li:after {
    opacity: 1;
    content: '';
    height: 1px;
    border-top: 1px dashed #D1D0D9;
    right: 90%;
    position: absolute;
    top: 50%;
    width: 64px;
    z-index: 1;
}

.content-cart-top-step .progressbar li:first-child:after {
    content: none;
}

.content-cart-top-step .progressbar li.active:after,
.content-cart-top-step .progressbar li.complete:after {
    border-top: 1px dashed #6AA543;
}

.content-cart-top-step .progressbar li.active:before {
    background: var(--primary-blue-logo, #243E85);
    border-color: var(--primary-blue-logo, #243E85);
    color: #fff;
}

.content-cart-top-step .progressbar li.complete:before {
    content: url('../check.svg');
    background: var(--secondary-green, #2ECC70);
    border-color: var(--secondary-green, #2ECC70);
    color: #fff;
}


.content-cart-top-step .progressbar li.complete {
    color: var(--secondary-green, #2ECC70);
}

.content-cart-top-step .progressbar li.active {
    color: var(--primary-blue-logo, #243E85);
}

.alert {
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px transparent;
    padding: 7px 18px;
}

.alert svg {
    margin-right: 10px;
}

.alert-warning {
    margin: 15px 0 12px 0;
    background-color: #FFF9F0;
    border: 1px solid rgba(255, 168, 41, 0.5);
}

.row-cart {
    margin-left: -12px;
    margin-right: -12px;
}

.col-cart-padding-10 {
    padding: 0 10px;
}

.col-cart-padding-12 {
    padding: 0 12px;
}

.cart-list-products-order-minimum {
    background-color: #FFFFFF;
    border-radius: 8px;
}

.cart-list-products-order-minimum .title {
    color: #818898;
    margin-left: -6px;
    margin-right: -6px;
}

.cart-list-products-order-minimum .border-bottom {
    border-bottom: 1px solid #E5E7EB;
    padding: 11px 16px;
}

.cart-list-products-order-minimum .title li {
    padding: 0 6px;
}

.padding-16 {
    padding: 16px;
}

.cart-list-products-order-minimum ul.info {
    border-bottom: 1px solid #E5E7EB;
    margin-bottom: 16px;
    padding-bottom: 16px;
    font-weight: 600;
    margin-left: -6px;
    margin-right: -6px;
}

.cart-list-products-order-minimum ul.info li {
    padding: 0 6px;
}

.cart-list-products-order-minimum ul.info:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cart-list-products-order-minimum ul.info .img {
    margin-right: 16px;
}

.color-orange {
    color: #FFA829;
}

.btn-next-order {
    display: flex;
    height: 32px;
    padding: 11px 21px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #6AA543;
    color: #6AA543;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.cart-list-items h3 {
    font-weight: 600;
    font-size: 24px;
}

.list-tabs-products-cart-warning h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 12px;
    }
}

.cart-list-items label.box-checkbox-store {
    position: relative;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    margin-right: 16px;
    display: block;
}

.box-check-all-list-items {
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 12px;
}

.cart-list-items .box-checkbox-store .checkmark {
    background: #E5E7EB;
    border-radius: 8px;
}

.box-check-all-list-items>div {
    color: #818898;
}

.box-check-all-list-items .name span {
    color: #17181C;
    font-style: normal;
    font-weight: 400;
}

.row-margin-6 {
    margin-left: -6px;
    margin-right: -6px;
}

.row-margin-6>div {
    padding: 0 6px;
    flex-grow: 1;

    @media (max-width: 768px) {
        padding: 0 18px 0 6px;
    }
}

.box-check-all-list-items .padding-16 {
    padding: 12px 20px;
}

.cart--store {
    background: #FFFFFF;
    margin-bottom: 8px;
    border-radius: 8px;
}

.title-store--cart {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #17181C;
}

.title-store--cart.mobile {
    font-size: 12px;
}

.color-blue {
    color: #017BFF;
}

.cart--store .item-info .name .image {
    margin-right: 12px;
    width: 70px;
    position: relative;
}

.product-title-sku-quantity {
    width: calc(100% - 124px);
}

.cart--store .product-title-sku-quantity a {
    overflow: hidden;
    color: #17181C;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.cart--store.mobile .product-title-sku-quantity a {
    font-size: 12px;
}

.product-title-sku-quantity .sku {
    color: #818898;
    margin-bottom: 15px;
}

.product-quantity .flex--quantity {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 0;
    flex-direction: row;
}

.product-quantity .flex--quantity button {
    border: 1px solid #E5E7EB;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.product-quantity .flex--quantity button:disabled {
    background-color: #D5D7DD;
    cursor: default;
    pointer-events: none;
}

.product-quantity .input--qty {
    width: 44px;
    padding: 0 5px;
}

.cart--store .item-info .name .image label.outofstock {
    border-radius: 222px;
    background: rgba(23, 24, 28, 0.60);
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    color: var(--neutral-white, #FFF);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.history-outofstock {
    border-radius: 222px;
    background: rgba(23, 24, 28, 0.60);
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    color: var(--neutral-white, #FFF);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.cart--store .item-info .name .image label.outofstock.mobile {
    top: 20%;
}

.item-info.item-info-out-of-stock .row-margin-6 .price,
.item-info.item-info-out-of-stock .row-margin-6 .dph,
.item-info.item-info-out-of-stock .row-margin-6 .total {
    color: #D1D0D9;
}

.cart-related-products {
    margin-top: 30px;
}

.cart--store .item-info {
    position: relative;
}

.cart--store .item-info:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #E5E7EB;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
}

.cart--store .item-info:last-child:after {
    display: none;
}

.box-order-confirmation {
    padding: 20px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.box-order-confirmation.mobile {
    padding-top: 0;
    padding-bottom: 0;
    position: unset;
}

.bg-box-order-confirmation {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 16px;
}

.box-order-confirmation .title {
    padding: 16px 16px 0;
    font-size: 16px;
    color: #17181C;
    line-height: 24px;
    font-weight: 600;

    @media (max-width: 1024px) {
        padding: 8px 8px 0;
        font-size: 12px;
    }
}

.box-order-confirmation ul {
    padding: 12px 16px;
    margin-bottom: 0;

    @media (max-width: 1024px) {
        padding: 8px;
    }
}

.box-order-confirmation ul li {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C6370;
    margin: 0 0 12px 0;

    @media (max-width: 1024px) {
        font-size: 12px;
    }
}

.box-order-confirmation ul li strong {
    color: #17181C;
}

.box-order-confirmation ul li span {
    color: #17181C;
    font-weight: 500;
}

.box-order-confirmation ul li:last-child {
    margin-bottom: 16px;
}

.box-order-confirmation ul li .text-blue-1000 {
    color: rgba(36, 62, 133, 1);
}

.box-order-confirmation ul li:last-child {
    margin-bottom: 0;
}

.color-green {
    color: #6AA543;
}

.btn--checkout {
    background: #6AA543;
    border-radius: 8px;
    display: block;
    text-align: center;
    font-weight: 500;
    color: #fff;
    padding: 15px 10px;
    width: 100%;
}

button.btn-more-products-order-minimum {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -16px;
    margin-bottom: 25px;
    position: relative;
}

button.btn-more-products-order-minimum.active svg {
    transform: rotate(180deg);
}

.content-cart-confirm-information {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
}

.content-cart-confirm-information .box-order-confirmation {
    padding-top: 0;
}

.content-cart-confirm-information h3 {
    font-weight: 600;
    font-size: 20px;
}

.content-cart-confirm-information .cart-confirm-description {
    margin-top: 1px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #5C6370;
    margin-bottom: 12px;
}

.cart-estimated-delivery-time {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 25px;
}

.cart-estimated-delivery-time .rte {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C6370;
}

.cart-address-info {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 16px;
}

.cart-address-info.mobile {
    padding: 12px 16px;
}

.cart-address-info.checked {
    background: linear-gradient(0deg, rgba(106, 165, 67, 0.05), rgba(106, 165, 67, 0.05)), #FFFFFF;
    border: 1px solid #6AA543;
    border-radius: 8px;
}

.cart-address-info .title {
    margin-bottom: 15px;
}

.cart-address-info .user-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #17181C;
}

.cart-address-info .user-name.mobile {
    font-size: 16px;
}

.cart-address-info .edit-address {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;
    display: flex;
    align-items: center;
}

.cart-address-info .edit-address span {
    display: block;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EFEFF1;
    border-radius: 8px;
}

.cart-address-info.mobile .edit-address span {
    background: unset;
    border: unset;
    padding: unset;
    width: unset;
    height: unset;
    display: unset;
}

.cart-address-info .select-shipping label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #17181C;
    padding-top: 3px;
    padding-left: 38px!important;
}

.cart-address-info .title h4 {
    font-size: 16px;
    font-weight: 600;
}

.cart-address-info .title button {
    font-weight: 600;
    font-size: 12px;
    color: #6AA543;
}

.cart-address-info .list .shipment-info {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #17181C;
    margin: 8px 0 18px 36px;
}

.cart-address-info .list .shipment-info.mobile {
    font-size: 14px;
    margin: 0;
}

.cart-address-info .list label {
    color: #17181C;
    margin-right: 10px;
}

span.default {
    background: #FFA829;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 25px;
}

p.default {
    display: flex;
    padding: 2px 8px;
    color: #017BFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 24px;
    background: rgba(1, 123, 255, 0.1);
    border-radius: 4px;
}

p.default.mobile {
    font-size: 12px;
}

.cart-address-info .list>div:last-child {
    margin: 0 0 10px 0;
}

.cart-address-other-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17181C;
    margin: 4px 0 16px;
}

.cart-address-other h5,
.cart-estimated-delivery-time .info h5 {
    font-weight: 600;
}

.cart-address-other p {
    color: #818898;
}

.cart-address-other p.text-red-500 {
    color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.cart-estimated-delivery-time .info a {
    color: #6AA543;
    font-weight: 600;
}

.cart-estimated-delivery-time .info a svg {
    margin-left: 6px;
}

.cart-estimated-delivery-time .info .title {
    margin-bottom: 5px;
}

.cart-button--groups {
    margin-top: 24px;
}

.cart-button--groups.mobile {
    margin-top: 8px;
}

.cart-button--groups .btn {
    border: 1px solid #6AA543;
    border-radius: 8px;
    color: #6AA543;
    font-weight: 600;
    height: 48px;
    width: calc(50% - 8px);;
}

.cart-button--groups button.btn.btn-checkout-continue-done {
    width: 100%;
    color: #ffffff;
}

.cart-button--groups .form-checkout-continue {
    width: calc(100% - 50% - 8px);
    height: 48px;
    border-radius: 8px;
}

.bg-box-order-confirmation .text {
    padding: 16px 16px 20px 16px;
}

.font-size-16 {
    font-size: 16px;
}

.color-818898 {
    color: #818898;
    word-break: break-word;
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: #E5E7EB;
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    transition: all .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ant-switch:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #0000001a;
}

.ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    transition: all .2s ease-in-out;
}

.ant-switch-handle:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    box-shadow: 0 2px 4px #00230b33;
    transition: all .2s ease-in-out;
    content: "";
}

.ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    transition: margin .2s;
}

.cart-address-other {
    position: relative;
}

.cart-address-other .ant-switch {
    position: absolute;
    top: 15px;
    right: 15px;
}

.ant-switch-checked {
    background-color: #6AA543;
}

.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
}

.ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
}

.cart-form-address-new {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #6AA543;
    border-radius: 8px;
}

.close-modal-cart-address {
    position: absolute;
    top: 16px;
    right: 16px;
}

.modal-cart-list-address h3 {
    font-size: 20px;
    font-weight: 600;
    color: #17181C;
}

.modal-cart-list-address h3.mobile {
    font-size: 16px;
}

.default-star {
    background: #FFA829;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 25px;
    margin-left: 8px;
}

.default-star svg {
    margin-right: 5px;
}

.modal-col-address .label {
    margin: 0 0 9px 0;
}

.modal-col-address .address {
    position: relative;
}

.modal-col-address .address input {
    padding-right: 50px;
    font-weight: 600;
    color: #17181C;
}

.modal-col-address .address button {
    position: absolute;
    right: 16px;
    top: 18px;
}

.btn-add-new-address-cart {
    font-weight: 600;
    color: #6AA543;
    position: relative;
    padding-left: 28px;
}

.btn-add-new-address-cart:before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background-color: #6AA543;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 0;
}

.btn-add-new-address-cart:after {
    content: '+';
    display: block;
    position: absolute;
    color: #fff;
    top: -3px;
    left: 4px;
    font-size: 18px;
}

.btn--save-cart-address {
    background-color: #6AA543;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    padding: 14px 20px;
    display: inline-block;
    max-width: 280px;
    min-width: 280px;
    width: 100%;
    margin: 0 0 10px 0;
}

.btn--save-cart-address-mobile {
    background-color: #6AA543;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    padding: 14px 20px;
    display: inline-block;
    width: 100%;
    margin: 0 0 10px 0;
}

@media (min-width: 768px) {
    .content-cart-confirm-information .col-cart-left {
        width: 65%;
    }

    .content-cart-confirm-information .col-cart-right {
        width: 35%;
    }
}

.box-cart-complete {
    max-width: 496px;
    width: 100%;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 16px;
    color: #818898;
    padding: 32px;
}

.box-cart-complete .img {
    padding: 0 0 16px 0;
}

.box-cart-complete .img svg {
    display: inline-block;
}

.box-cart-complete h3 {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 16px 0;
}

.box-cart-complete .phone {
    display: block;
    font-weight: 600;
    color: #6AA543;
    margin-bottom: 20px;
}

.box-cart-complete .note {
    font-style: italic;
    margin-bottom: 20px;
}

.box-cart-complete a {
    background-color: #6AA543;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: block;
    padding: 16px 15px;
    text-transform: capitalize;
}

.box-cart-complete a:hover {
    background-color: #558536;
}

.btn-delete-store-cart {
    position: relative;
    /* z-index: 90; */
}

.btn-delete-store-cart span {
    display: block;
    position: absolute;
    top: 40px;
    text-indent: 0;
    background: #fff;
    padding: 12px 13px;
    line-height: 1;
    border-radius: 8px;
    margin-bottom: 10px;
    min-width: 50px;
    white-space: pre;
    text-align: center;
    text-transform: none;
    visibility: hidden;
    opacity: 0;
    border: 1px solid #E5E7EB;
    z-index: 100;
    right: -16px;
}

.btn-delete-store-cart:after,
.btn-delete-store-cart:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    position:absolute;
    z-index: 12;
    bottom: -17px;
    right: 3px;
    visibility: hidden;
    opacity: 0;
}

.btn-delete-store-cart:before {
    border-color: transparent transparent #6A7388 transparent;

}

.btn-delete-store-cart:after {
    margin-top: -2px;
    border-color: transparent transparent #ffffff transparent;
}

.btn-delete-store-cart:hover span,
.btn-delete-store-cart:hover:before,
.btn-delete-store-cart:hover:after {
    visibility: visible;
    opacity: 1;
}

.modal-delete-store-cart h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 15px 0;
}

.modal-delete-store-cart p.text-sm.text-gray-500 {
    color: #818898;
    font-size: 16px;
    margin-bottom: 35px;
}

.modal-delete-store-cart p.text-sm.text-gray-500 strong {
    font-weight: 600;
}

.modal-delete-store-cart .button-group {
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
}

.modal-delete-store-cart .button-group button {
    display: block;
    text-align: center;
    width: 100%;
    color: #6AA543;
    font-weight: 600;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 12px 10px;
    margin: 0 8px;
    border-color: #6AA543;
}

.modal-delete-store-cart .button-group button.btn--cancel-modal-store-cart {
    border-color: #6AA543;
}

.modal-delete-store-cart .button-group button.btn--dlt-modal-store-cart {
    border-color: #FF1D30;
    background-color: #FF1D30;
    color: #ffffff;
}

.image-modal-delete-all-stores {
    margin: 0 0 15px 0;
}

.image-modal-delete-all-stores svg {
    display: inline-block;
}

.list-tabs-products-cart-warning .tabs-title button {
    margin-right: 12px;
    border-radius: 8px;
    border: 1px solid transparent;
    border-color: #E5E7EB;
    padding: 10px 16px;
    border-radius: 100px;
}

.list-tabs-products-cart-warning .tabs-title button:hover,
.list-tabs-products-cart-warning .tabs-title button[aria-selected="true"] {
    border-color: #6AA543;
    background-color: #6AA543;
    color: #fff;
}

.list-tabs-products-cart-warning .cart--store .title .row-margin-6>div {
    color: #818898;
}

.list-tabs-products-cart-warning .cart--store .title .padding-16 {
    padding-top: 11px;
    padding-bottom: 11px;
}

.list-tabs-products-cart-warning .cart--store .item-info .name .image {
    position: relative;
}

.list-tabs-products-cart-warning .cart--store .item-info .name .image label.outofstock {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
}

.cart-list-products-change-price {
    margin-bottom: 20px;
}

.cart-list-products-change-price ul li .image {
    margin-right: 15px;
    width: 74px;
}

.cart-list-products-change-price ul li .product-title-sku-quantity,
.cart--list-products-outofstock .product-title-sku-quantity {
    width: calc(100% - 89px);
}

.cart--list-products-outofstock .product-title-sku-quantity .sku,
.cart-list-products-change-price .product-title-sku-quantity .sku  {
    margin: 0;
}

.cart--list-products-outofstock.cart--store .item-info:after {
    left: 16px;
    right: 16px;
}

.cart-list-products-change-price.cart-list-products-order-minimum ul.info:last-child {
    border-bottom: 1px solid #E5E7EB;
    margin-bottom: 16px;
    padding-bottom: 16px;
}

.cart-list-products-change-price button.btn-more-products-order-minimum {
    margin-top: -46px;
}

.cart--store.border-active {
    border: 1px solid #6AA543;
}

.modal-checkout--continue.modal-delete-store-cart .btn-modal--checkout-continue {
    background-color: #6AA543;
    color: #fff;
}

.modal-checkout--continue.modal-delete-store-cart .btn-modal--checkout-continue:hover {
    background-color: #558536;
}


.modal-checkout--continue h3,
.modal-checkout--continue .button-group button {
    text-transform: capitalize;
}
.template-shop-detail {
    background-color: #FAFAFA;
}

.template-shop-detail .avg-rating-card {
    background: rgba(255, 168, 41, 0.05);
    border: 1px solid rgba(255, 168, 41, 0.5);
}

.template-shop-detail .avg-rating-card .avg-rate {
    color: rgba(255, 168, 41, 1);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 30px;
}

.template-shop-detail .avg-rating-card .total-rate {
    color: rgba(255, 168, 41, 1);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 30px;
}

.slick-product-carousel-two.product-seen {
    margin-left: -5px;
    margin-right: -5px;
}

.template-shop-detail .shop-info-minimum-order-popup {
    width: 197px;
    top: 37px;
    right: 0px;
    padding: 8px 15px;
}

.template-shop-detail .shop-info-minimum-order-popup:before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(var(--color-accent));
    position: absolute;
    top: -5px;
    right: 10px;
}

/*** Add to cart ***/
.openModal-login-to-addToCart {
    color: #FFFFFF;
    font-weight: 600;
    background-color: #67A729;
    width: 100%;
    border-radius: 8px;
    height: 30px;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.openModal-login-to-addToCart:hover {
    background-color: rgba(var(--color-accent-hover));
}

.quantity-group {
    position: relative;
}

.product-detail .quantity-group.show-tooltip:before {
    content: attr(title);
    position: absolute;
    bottom: 80%;
    text-indent: 0;
    background: #FF3D43;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 10px;
    min-width: 50px;
    color: #fff;
    left: 16%;
    white-space: pre;
    text-align: center;
    text-transform: none;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transition: all 250ms ease-out;
}

.product-detail .quantity-group.show-tooltip:after {
    content: "";
    position: absolute;
    left: 14%;
    bottom: 80%;
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #FF3D43 transparent transparent transparent;
    margin-bottom: 6px;
    margin-left: -4px;
    transition: all 250ms ease-out;
}

.product-detail .input-group {
    width: auto;
}

.quantity-group.show-tooltip:before {
    content: attr(title);
    position: absolute;
    bottom: 80%;
    text-indent: 0;
    background: #FF3D43;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 10px;
    min-width: 50px;
    color: #fff;
    left: 97%;
    white-space: pre;
    text-align: center;
    text-transform: none;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transition: all 250ms ease-out;
}

.quantity-group.show-tooltip:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 80%;
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #FF3D43 transparent transparent transparent;
    margin-bottom: 6px;
    margin-left: -4px;
    transition: all 250ms ease-out;
}

.product--quantity input.form-control.text-center.input--quantity {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    min-width: 70px;
    max-width: 70px;
    border-top: 1px solid #D5D7DD;
    border-bottom: 1px solid #D5D7DD;
}

button.btn.btn-success.btn-sm.rounded-pill.btn-addtocart-product-item.height-32px {
    height: 30px;
}

.parent-list-items-minicart .list-items-minicart.collapsed {
    display: block;
}

.btn-defautl-minicart[disabled] {
    opacity: 0.5;
}

/*** Page Cart **/
.template-cart-no-products .no-products-minicart {
    max-width: 100%;
    padding: 35px 0;
}

.template-cart-no-products .no-products-minicart h4 {
    max-width: 100%;
    margin-top: 0;
}

.template-cart-no-products .no-products-minicart .btn {
    display: inline-block;
}

.cart-list-items {
    margin-top: 30px;
}

button.btn-update-all-changed-price {
    display: inline-block;
    vertical-align: middle;
    color: #6AA543;
    font-weight: 600;
    position: relative;
}

button.btn-update-all-changed-price:before {
    content: "";
    position: absolute;
    left: -15px;
    top: 5px;
    display: block;
    width: 5px;
    height: 10px;
    border: solid #6AA543;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) scale(.9);
}

.modal-col-address {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 0;
}

.modal-col-address.mobile {
    padding: 0;
}

.modal-col-address .address>div {
    margin: 0 0 20px 0;
}

.modal-col-address.mobile .address>div {
    margin: 0 0 16px 0;
}

.modal-col-address label {
    font-weight: 600;
    color: #17181C;
    font-size: 14px;
    line-height: 20px
}

.modal-col-address .address>div:last-child {
    margin: 0;
}

.modal-col-address.mobile .address>div:last-child {
    margin: 8px 0 32px;
}


.list-items-minicart input[type=number]::-webkit-inner-spin-button,
.list-items-minicart input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.list-items-minicart input.form-control.text-center.input--quantity {
    -moz-appearance: textfield;
    width: 100%;
}

/*** Variant Options ***/
.product-options ul li.active-variant {
    background: rgba(106, 165, 67, 0.1);
    border: 1px solid #6AA543;
    color: #6AA543;
}

.product-options ul li.out-of-stock {
    cursor: default;
    position: relative;
    opacity: 0.7;
}

.product-options ul li.out-of-stock:before {
    content: "";
    position: absolute;
    border-top: 1px dashed #5C6370;
    width: var(--diagonalLength);
    height: 100%;
    left: 0;
    top: 100%;
    transform-origin: 0 0;
    transform: rotate(var(--angle, -45deg));
    z-index: 1000;
}

.option-values-minicart {
    margin: 0 0 5px 0;
}

.option-values-minicart span {
    border-radius: 222px;
    background: #EDEDED;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    color: var(--neutral-maintext, #17181C);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.product-title-sku-quantity .option-values-minicart {
    margin: 0 0 8px 0;
}

.profile .profile-header button {
    padding: 0 16px;
}

@media (min-width: 768px) {
    .profile .profile-form .profile-input {
        width: 704px;
    }
}

.profile .profile-form .profile-avatar .preview-image {
    width: 100px;
    height: 100px;
}

.profile-form input {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #17181C;
    height: 54px;
}

.profile-form label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17181C;
}

.profile-form .profile-input button {
    line-height: 20px;
    height: 54px;
}

.active-star-pattern {
    background-image: url('../img/active-star-pattern.svg');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 97% 50%;
    padding-right: 44px;
}

.inactive-star-pattern {
    background-image: url('../img/inactive-star-pattern.svg');
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 97% 50%;
    padding-right: 44px;
}

.notification-more-actions {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.notification-more-actions::before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(var(--color-light));
    position: absolute;
    top: -5px;
    right: 22px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.notification-mobile-more-actions {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.notification-mobile-more-actions::before {
    content: '';
    width: 0px;
    height: 0px;
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid rgba(var(--color-light));
    position: absolute;
    top: 12px;
    right: -10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.notification-detail-menu {
    max-height: calc(100vh - 250px);
}

.date-picker-icon {
    background-image: url('../img/calendar-icon.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 95% 50%;
}

.custom-table thead.rc-table-thead th {
    @apply bg-light text-gray-1100 text-sm font-normal text-left last:text-right first:pl-8 last:pr-10 py-4;
}

.custom-table tr.rc-table-row td {
    @apply first:pl-8 last:text-right last:pr-[28px] text-sm text-gray-1000 cursor-default py-5 border-b border-gray-1300;
}

.custom-table tr.rc-table-row td a {
    @apply text-accent cursor-pointer
}

.order-detail .order-detail-info {
    height: calc(100% - 32px);
}

.orders-products-bill thead.rc-table-thead th  {
    @apply bg-light py-3 border-b border-gray-1300 text-gray-1500 font-normal text-sm px-2 align-top last:pr-4;
}

.orders-products-bill tr.rc-table-row td {
    @apply border-b border-gray-1300 py-3 align-top text-gray-1500 text-sm first:px-0 px-2 last:pr-4 relative;
}

.order-detail .calculator-price .title {
    @apply w-[116px] text-right
}

.order-detail .calculator-price .price {
    min-width: 156px;
    width: calc(100% - 200px);
    padding-left: 16px;
    text-align: right;
    font-weight: 600;
}

.order-detail .rc-table-cell-scrollbar {
    display: none;
}

.slick-track {
    margin: unset;
}

.list-shops-new .slick-track,
.slice-hot-product .slick-track {
  margin-left: 11px;
  margin-right: unset;
}

.main-cart-item .quantity-group.show-tooltip:before {
    content: attr(title);
    position: absolute;
    bottom: 80%;
    text-indent: 0;
    background: #FF3D43;
    padding: 5px 10px;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 10px;
    min-width: 50px;
    color: #fff;
    left: 45%;
    white-space: pre;
    text-align: center;
    text-transform: none;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transition: all 250ms ease-out;
}

.main-cart-item .quantity-group.show-tooltip:after {
    content: "";
    position: absolute;
    left: 23%;
    bottom: 80%;
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #FF3D43 transparent transparent transparent;
    margin-bottom: 6px;
    margin-left: -4px;
    transition: all 250ms ease-out;
}

.bg-gray-order {
    background-color: rgba(229, 231, 235, 0.3);
}

.select-extend-order ul {
    padding-top: 0;
    z-index: 50;
}

.select-extend-order ul li {
    display: block;
    margin: 0;
}

.select-extend-order .list-options {
    max-height: 280px;
    overflow-y: auto;
}

.product-max-quantity .product-title-sku-quantity {
    width: unset;
}

.extend-orders .slick-prev {
	left: 0;
	z-index: 50;
}

.extend-orders .slick-prev.slick-disabled,
.extend-orders .slick-next.slick-disabled {
	display: none!important;
}

.extend-orders .slick-next {
	right: 0;
	z-index: 50;
}

.extend-orders .slick-track {
	margin: unset;
}

.claim-table thead.rc-table-thead th {
    @apply bg-light font-semibold border text-xs font-normal py-4;
    background-color: #F1F1F4;
    color: #5C6370;
}

.claim-table tr.rc-table-row td {
    @apply text-sm text-black cursor-default border py-4;
}

.bg-product-claim {
    background-color: rgba(229, 231, 235, 0.2);
}

.bg-color-green-10-01 {
    background-color: rgba(46, 204, 112, 0.1)
}

.bg-color-red-10-01 {
    background-color: rgba(255, 29, 48, 0.1);
}

.pb-30px {
    padding-bottom: 30px;
}

.claims-border-tip {
    border: 1px solid rgba(255, 168, 41, 0.5);
}

.list-option-no-data {
    height: 176px!important;
}

.banner-slider .slick-dots .slick-active div {
    opacity: 1;
}

.banner-slider button.slick-arrow.slick-prev {
    left: 16px;
}

.banner-slider button.slick-arrow.slick-next {
    right: 16px;
}

.display-unset {
    display: unset !important;
}

.display-moble-navigator {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.display-moble-navigator span {
    display: block;
}

.left-30-important {
    left: 30px !important;
}

.btn-wishlist-header-mobile svg {
    position: relative;
}

.btn-wishlist-header-mobile .count{
    background-color: #ff1d30;
}

.body-page {
    min-height: calc(100vh - 400px);
}

.text-align-webkit-center {
    text-align: -webkit-center;
}

.shadow-menu-mobile {
    box-shadow: 0px -4px 10px rgb(0 0 0 / 5%);
}

.width-categories-mobile {
    width: 100% !important;
}

.mobile-see-all {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}

.info-popup {
    width: 197px;
    top: 37px;
    right: -80px;
    padding: 8px 15px;
}

.info-popup:before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(var(--color-accent));
    position: absolute;
    top: -5px;
    right: 90px;
}

.slider-customer-dot-mobile .slick-dots button {
    width: 30px!important;
    border-radius: unset!important;
    height: 4px!important;
    background-color: #E5E7EB!important;
}

.slider-customer-dot-mobile .slick-slider ul.slick-dots li.slick-active button,
.slider-customer-dot-mobile .slick-slider ul.slick-dots li button:hover {
    background-color: #6AA543!important;
}

.align-webkit-center {
    text-align: -webkit-center;
}

.display-webkit-box {
    display: -webkit-box;
}

.custom-bg-silver {
    background: rgba(106, 165, 67, 0.05);
}

.ReactCollapse--collapse {
    transition: height 300ms;
}

.border-none {
    border: 0;
}

.phone-number {
	position: relative;
}

.phone-number::before {
	content: '+420';
	display: block;
	font-weight: 600;
	line-height: 20px;
	position: absolute;
	font-size: 14px;
	left: 20px;
	top: 39px;
}

.input-profile::before {
    top: 49px;
    left: 16px;
}

.input-profile input {
    padding-left: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-uploader .upload {
    width: 100%;
}

.bg-notification {
    background-color: #FFA829 !important;
}

.slice-mobile .carousel-cell{
    padding-left: 0px !important;
}

.slice-mobile button.slick-arrow.slick-next {
    right: -4px;
}

.slice-mobile button.slick-arrow.slick-prev {
    left: 2px;
}

.py-0-important {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.modal-col-address .address-mobile {
    border: 0;
    padding: 0;
}

.mobile-product-outofstock {
    background-color: #818898;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    border-radius: 100px;
    font-size: 10px;
    font-weight: 600;
    padding: 5px;
    margin: 8px 0 0 0;
    display: block;
}

.margin-0 {
    margin: 0!important;
}

.outofstock-mobile {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translate(0, -50%);
}

.text-ellipsis {
	text-overflow: ellipsis;
}

.inline-block-important {
    display: inline-block !important;
}

@-webkit-keyframes fade-out {
    0% { opacity: 1; -webkit-transform: scale(1);}
    85% {opacity: 1; -webkit-transform: scale(1.05);}
    100% {-webkit-transform: scale(.1); opacity: 0;}
}

.fade-out {
    -webkit-animation: fade-out .5s ease-in;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    opacity: 1;
}


@-webkit-keyframes fade-in {
    0% { opacity: 0; -webkit-transform: scale(0.1);}
    85% {opacity: 1; -webkit-transform: scale(0.75);}
    100% {-webkit-transform: scale(1); opacity: 1;}
}

.fade-in {
    -webkit-animation: fade-in .5s ease-in;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    opacity: 1;
}

.product-box-image-options label.outofstock {
    background-color: #818898;
    color: #fff;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    margin: 8px 0 0 0;
    display: block;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.product-box-image-options label.outofstockmobile {
    background-color: #818898;
    color: #fff;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
    margin: 8px 0 0 0;
    display: block;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}

.suggestion-group {
    width: 100%;
    position: relative;
}

.suggestion-group .react-autosuggest__input {
    width: 100%;
    padding: 12px 90px 12px 48px !important;
    border-radius: 8px;
    font-size: 14px;
    background: #EDEDED;
    height: 40px;
    border: 0 !important;
}

.suggestion-group .react-autosuggest__container {
    width: 100%;
}

.suggestion-group #react-autowhatever-destination {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 200;
    font-size: 14px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-height: 450px;
    overflow-y: auto;
}

.suggestion-group #react-autowhatever-destination .suggestion-item {
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;
}

.suggestion-group #react-autowhatever-destination .suggestion-item:hover {
    background-color: #67A729;
    color: #fff;
}

.list-suggestion {
    height: calc(100% - 130px);
    overflow: auto;
}

.product-gallery label.outofstock {
    background-color: #818898;
    color: #fff;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 28px;
    font-weight: 600;
    padding: 5px 15px;
    display: block;
    position: absolute;
    z-index: 90;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.product-gallery label.outofstockmobile {
    background-color: #818898;
    color: #fff;
    text-transform: uppercase;
    border-radius: 100px;
    font-size: 28px;
    font-weight: 600;
    padding: 5px;
    display: block;
    position: absolute;
    z-index: 90;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}

.cart-subtotal {
    min-width: 65px!important;
    padding: 4px 0!important;
}

.categories-state-three {
    border-bottom: 1px solid #E5E7EB;
}

.categories-state-three li {
    border: 0!important;
    padding: 6px 0 6px 40px!important;
    color: #17181C;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.set-border-0 {
    border: 0!important;
}

.set-padding-0 {
    padding: 0!important;
}

.button-sort-price:hover svg path {
    stroke: #fff;
}

.clear-button {
    background-color: #fff;
}

.icon-scrollbar-groups {
	overflow-x: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.icon-scrollbar-groups::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

#productGallery .swiper-slide {
    visibility: hidden;
}

#productGallery .swiper-slide.swiper-slide-active {
    visibility: unset;
}

#productGallery .swiper-slide img {
    border-radius: 8px;
}

.swiper-slide-thumb-active {
    border: 1px solid #6AA543;
}

.mySwiper .swiper-pagination-bullets {
    bottom: 0px!important;
}

.mySwiper .swiper-pagination-bullet-active {
    background-color: #67A729!important;
}

.mySwiper .swiper-button-next::after {
    content: '';
    background-image: url('../img/icon-arrow-next.svg') !important;
    width: 11px;
    height: 20px;
    background-size: contain;
}

.mySwiper .swiper-button-prev:after {
    content: '';
    background-image: url('../img/icon-arrow-next.svg') !important;
    width: 11px;
    height: 20px;
    background-size: contain;
    position: absolute;
    transform: rotate(180deg);
}

.thumb-carousel .mySwiper .swiper-button-next,
.thumb-carousel .mySwiper .swiper-button-prev {
    top: 22px;
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    background: #EDEDED;

    @media (max-width: 640px) {
        display: none;
    }
}

.thumb-carousel .swiper {
    position: unset!important;
}

.thumb-carousel .mySwiper .swiper-button-next {
    right: -32px;
}

.thumb-carousel .mySwiper .swiper-button-prev {
    left: -32px;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
    top: 60%;
    background-color: #ffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mySwiper .swiper-button-next {
    right: 1px;
}

.mySwiper .swiper-button-prev {
    left: 1px;
}

.navigator-mobile .slick-dots {
    bottom: -28px
}

.navigator-mobile .slick-dots li {
    margin: 0;
    height: 4px;
}

.navigator-mobile .slick-dots li button {
    width: 100%;
    border-radius: 0;
    height: 4px;
}

.navigator-mobile .slick-dots li:first-child button {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.navigator-mobile .slick-dots li:last-child button {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.navigator-mobile .slick-slider ul.slick-dots li button:hover {
    background-color: #C4C4C4;
}


.navigator-mobile .slick-slider ul.slick-dots li.slick-active button {
    background-color: #6AA543!important;
}

.image-out-stock img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.cart-confirm-more-action:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    right: 6px;
}

::-webkit-input-placeholder { /* Edge */
    font-weight: 400;
    color: #D1D0D9;
}

:-ms-input-placeholder { /* Internet Explorer */
    font-weight: 400;
    color: #D1D0D9;
}

::placeholder {
    font-weight: 400;
    color: #D1D0D9;
}

.cart-detail-note-btn:hover svg path {
    fill: white;
}

.padding-12 {
    padding: 12px 0;
}

.cart-shop-collection-class {
    height: calc(100vh - 176px);
}

.button-delete-order:hover svg path {
	stroke: #fff;
}

.gift-product-section {
    height: calc(100vh - 400px);
}

.gift-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.be-viet-pro,
.be-viet-pro button,
.be-viet-pro input,
.be-viet-pro optgroup,
.be-viet-pro select,
.be-viet-pro textarea,
.be-viet-pro h1,
.be-viet-pro h2,
.be-viet-pro h3,
.be-viet-pro h4,
.be-viet-pro h5,
.be-viet-pro h6 {
    font-family: 'Be Vietnam Pro'!important;
}

.menu-button-active {
    color: #67A729;
}

.menu-button-active svg path {
    stroke: #67A729;
}

.icon-button-active svg path {
    fill: #67A729;
}

.category-shop-all:hover .icon svg path {
    stroke: #67A729;
}

.highlight {
    color: rgba(1, 123, 255, 1)!important;
}

.menu-hover:hover svg path {
    stroke: #67A729!important;
}

.menu-hover:hover div {
    color: #67A729!important;
}

.grow-0 {
    flex-grow: 0;
}

.shrink-0 {
    flex-shrink: 0;
}

.grow {
    flex-grow: 1;
}

.product-invalid-popup {
    max-height: calc(100vh - 110px);
}

@media (min-width: 768px){
    .modal-height {
        max-height: 85vh;
        overflow-y: auto;
        border-radius: 8px;
    }

    .product-detail-content-height {
        max-height: 75vh;
        overflow-y: auto;
    }
}

.categories-filter-search-input {
	height: 40px;
	color: #17181C;
	font-weight: 400;
	padding: 0 48px;
}

.categories-filter-search-input:focus {
	border-color: #6AA543;
    border: 0;
}

.categories-filter-search-input::placeholder {
	color: #5C6370;
	font-size: 14px;
	font-weight: 400;
}

.word-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cal-214px {
    overflow: auto;
    height: calc(100vh - 214px);
}

.cal-180px {
    overflow: auto;
    height: calc(100vh - 350px);
}

.mobile-filter-popup .react-datepicker-wrapper {
    display: unset;
}

.date-picker-hide-diablog .react-datepicker {
    display: none;
}

.date-picker-hide-diablog .react-datepicker.inline-datepicker {
    display: inline-block;
}

.header-sidebar-minicart  {
    overflow: auto;
    height: calc(100vh - 66px);
}

.icon-scrollbar-groups-visible {
	overflow-x: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: visible;
}

.categories-filter-mobile-height {
    height: calc(100vh - 106px);
}

.shop-cover-image {
    opacity: 0.7;
    position: absolute;
    top: 0;
    border-radius: 12px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(41, 51, 78, 0.68) 89.06%);
}

.shop-logo-image {
    border-radius: 8px;
    border: 2px solid var(--Neutral-White, #FFF);
}

.upload-image-user {
    padding: 4.2rem
}

.alert-update-ico {
    border: 1px solid #FFA829;
    border-radius: 5px;
    padding: 5px;
    background: #FFFBE6;
}
.alert-update-ico .description {
    color: #FFA829;
}
.alert-update-ico .update-now {
    color: #6AA543;
    font-weight: 600;
    cursor: pointer;
}
.btn-skip-update-ico {
    border: 1px solid #6AA543;
}

.auto-suggest-search-input {
    width: 100%;
}
.category-result {
    overflow: auto;
    height: 65px; 
}

.shop-result {
    overflow: auto;
    height: 150px; 
}
.header-pc {
    background: #fff;
}
.header-search-form {
    width: 100%;
    margin-left: 20px;
}
.clear-search {
    right: 90px;
}
.horizontal-line {
    border: 0.5px solid #EDEDED;
}
.keyword-bar {
    margin: 10px 0px 10px 0px;
}
.keyword-bar li {
    font-weight: 400;
    font-size: 14px;
    font-family: 'Be Vietnam Pro' !important;
    color:#5C6370;
}
.keyword-bar .key-word-name:hover {
    color:#49A32A
}

.slick-slide img {
    height: 210px;
}

.selector-top {
    margin-top: 25px;
}

.selector-top .banner .container {
    padding: 0 !important;
}
.selector-top .category {
    margin-top: 20px;
}
.selector-top .banner-slider .slick-list img {
    width: 100%;
    border-radius: 15px;
    object-fit: fill;
    cursor: pointer;
    height: 260px !important;
}

.selector-top .banner-slider .slick-list .slick-current {
    padding-right: 20px;
}

.selector-top .banner-category-group {
    width: 78%;
}
.selector-top .shop {
    width: 20%;
}
.selector-top .p-shop {
    padding: 15px 0 0 15px;
}
.selector-top .shop ul li {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color:#17181C !important
}

.selector-top .shop h2 {
    font-size: 20px;
    font-weight: 600;
}
.selector-top .shop .input-search {
    padding: 0px 10px 0px 10px;
}

.selector-top .category .info {
    cursor: pointer;
    text-align: center;
}
.selector-top .category .info:hover .category-border {
    border: 1px solid #49A32A;
}

.selector-top .category .info:hover .name {
    color: #49A32A;
}

.selector-top .category h2 {
    font-size: 20px;
    font-weight: 600;
}

.selector-top .category .name {
    margin-top: 15px;
    font-size: 14px;
    text-transform: lowercase;
    color:#17181C;
    font-weight: 500;
}

.selector-top .category .name::first-letter {
    text-transform: capitalize;
}

.selector-top .category-border {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 1px solid #ccc;
    overflow: hidden;
}

.selector-top .category-border img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.selector-top .shop-name, .category-header .shop-name {
    width: 80%;
}

#tabs-menu-category .nav-item, .content-submenu-dm-pc ul li {
    cursor: pointer;
}

#tabs-menu-category .nav-item:hover span, .shop ul li:hover .shop-name {
    color: rgba(var(--color-accent), var(--tw-text-opacity))
}

@media (max-width: 1020px) {
    .selector-top .banner-slider .slick-list .slick-current {
        padding-right: 0px;
    }
}

@media (max-width: 1270px) {
    .list-shops-new {
        margin-top: 2rem;
    }
}

.i33-slogan .free-ship {
    color: #6AA543;
    border-right: 1px solid #e8e8e8e8;
    padding-right: 15px;
    cursor: pointer;
}
.i33-slogan .free-ship span, .date-ship span, .date-complaint span {
    padding-left: 5px;
}

.i33-slogan .date-ship, .date-complaint {
    padding: 0px 15px 0px 15px;
    border-right: 1px solid #e8e8e8e8;
}

.i33-slogan .price-from-owner  {
    padding: 0px 15px 0px 15px;
}
.header-top-slogan {
    padding: 13px 0px 13px 0px;
}
.shop-info ul {
    list-style: unset !important;
}