@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    /* --color-accent: 0, 159, 127; */
    --color-accent: 106, 165, 67; /* Custom color-accent*/

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 85, 133, 54;

    /*
    * Hover state color of primary color
    */
    --color-accent-disabled: 199, 222, 183;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

     /*
    * shade of primary color
    */
    --color-accent-800: 85, 133, 54;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 107, 114, 128;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 31, 41, 55;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;

    /*
    * gray-1000
    */
    --color-gray-1000: 23, 24, 28;

    /*
    * gray-1100
    */
    --color-gray-1100: 129, 136, 152;

    /*
    * gray-1200
    */
    --color-gray-1200: 204, 204, 204;

    /*
    * gray-1300
    */
    --color-gray-1300: 225, 225, 225;

    /*
    * gray-1400
    */
    --color-gray-1400: 238, 238, 238;

    /*
    * gray-1500
    */
    --color-gray-1500: 92, 99, 112;

    /*
    * gray-1600
    */
    --color-gray-1600: 241, 241, 244;

    /*
    * gray-1700
    */
    --color-gray-1700: 249, 249, 249;

    /*
    * gray-1800
    */
    --color-gray-1800: 239, 239, 241;

    /*
    * gray-1900
    */
    --color-gray-1900: 239, 239, 241;

     /*
    * gray-2000
    */
    --color-gray-2000: 250, 250, 250;

     /*
    * gray-2100
    */
    --color-gray-2100: 209, 208, 217;

    /*
    * gray-2200
    */
    --color-gray-2200: 213, 215, 221;

    /*
    * gray-2001
    */
    --color-gray-2001:  142, 142, 147;

    /*
    * gray-2300
    */
    --color-gray-2300: 237, 237, 237;

    /*
    * blue-1000
    */
    --color-blue-1000: 1, 123, 255;

    /*
    * blue-1100
    */
    --color-blue-1100: 15, 95, 182;

    /*
    * yellow-1000
    */
    --color-yellow-1000: 255, 249, 240;

    /*
    * yellow-1100
    */
    --color-yellow-1100: 255, 168, 4;

    /*
    * yellow-1200
    */
    --color-yellow-1200: 255, 168, 41;

    /*
    * red-10
    */
    --color-red-10: 235, 83, 85;

    /*
    * orange-10
    */
    --color-orange-10: 255, 168, 41;

    /*
    * orange-20
    */
    --color-orange-20: 255, 251, 230;

    /*
    * orange-30
    */
    --color-orange-30: 255, 196, 0;

    /*
    * red-20
    */
    --color-red-20: 255, 29, 48;

    /*
    * red-30
    */
    --color-red-30: 255, 59, 48;

    /*
    * red-40
    */
    --color-red-40: 255, 210, 214;

    /*
    * green-10
    */
    --color-green-10: 46, 204, 112;

    /*
    * blue-10
    */
    --color-blue-10: 36, 62, 133;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  a {
    @apply no-underline;
  }
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./custom-plugins.css";
@import "./modules/auth.module.css";
