.login-register-form label,
.login-register-form button,
.login-register-form input
.login-register-form h1,
.login-register-form div {
  font-family: inherit;
}

.login-register-form .tab-list {
  margin: 0!important;
  font-size: 20px;
  line-height: 30px;
  color: rgba(var(--color-gray-1100));
  border-bottom: 1px solid rgb(var(--color-border-200));
  cursor: default;
}

.login-register-form .tab-list.active {
  color: rgb(var(--color-accent));
}

.login-register-form .tab-list .devider-active {
  margin: 20px 0 -1px;
  height: 5px;
  background-color: rgb(var(--color-accent));
  border-radius: 10px 10px 0px 0px;
}

.login-register-form input {
  height: 54px;
}

.login-register-form .select-specializes {
  height: calc(100vh - 500px);
  overflow-y: auto;
}

.login-form label {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(var(--color-gray-1000));
}

.login-form input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(var(--color-gray-1000));
}

.login-form label[for=password] svg {
  display: unset;
}

.login-form .forgot-password {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.login-form .checkbox-wrapper {
  margin-top: -8px
}

.login-form .login-btn {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
}

.login-form .text-devider {
  margin: 35px 0 15px;
  font-family: inherit;
}

.login-form .text-warning {
  font-family: inherit;
  color: rgba(var(--color-gray-1100));
}

.reset-form label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(var(--color-gray-1000));
}

.reset-form input {
  height: 54px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 16px 20px;
  color: rgba(var(--color-gray-1000));
}
